import '../index.css';
import 'react-toastify/dist/ReactToastify.css';

import { ApolloProvider } from '@apollo/client';
import { Global, ThemeProvider } from '@emotion/react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
// Importing as a _ component to make sure the Icon will be configured properly by default
import { Button, Icon as _ } from 'upbound-frontend-elements';

import { SentryEnvironments } from '@/@types/models';
import * as favicons from '@/assets/favicons';
import safariPinnedTab from '@/assets/safari-pinned-tab.svg';
import { PageTemplate } from '@/components/PageTemplate';
import { ReRouter } from '@/components/ReRouter';
import { AdminGuard } from '@/components/Spaces/AdminGuard';
import { LegacyGuard } from '@/components/Spaces/LegacyGuard';
import { SpaceGuard } from '@/components/Spaces/SpaceGuard';
import { MediaContextProvider, StyledSystemTheme } from '@/constants/styledTheme';
import { COLORS } from '@/constants/styles';
import { Modal } from '@/elements/Modal';
import { useGetCurrentUserProfileAndAccountsQuery } from '@/generated/upbound-graphql';
import { getUpboundGraphQLClient } from '@/graphql';
import { setAuthHasErrorRV, useAllAccounts, useAuthHasErrorRV, useCurrentUserRaw } from '@/graphql/reactiveVars';
import { applyPolyfillsGlobally } from '@/polyfills';
import { external } from '@/routes';
import { globalStyle, StyledToastContainer } from '@/styles';
import { IntercomBoot, IntercomUpdate } from '@/utils/intercom';
import { setModalElement } from '@/utils/modal';

export type NextPageWithConfig<P = {}, IP = P> = NextPage<P, IP> & {
  config?: {
    /** Indicates to the ReRouter what "section" this page is in to assist in determining if and when
     *  rerouting is needed */
    reRoute?: string;
    title?: string;
    /** If true or omitted the page will be rendered inside the default page layout.
     *  If false the page will be rendered without a layout.
     *  A react component may be passed to be used instead of the default layout. */
    layout?: React.ComponentType<React.PropsWithChildren> | boolean;
    layoutProps?: Record<string, unknown>;
  };
};

type AppPropsWithConfig = AppProps & {
  Component: NextPageWithConfig;
};

applyPolyfillsGlobally();

function getTitlePostfix(env: SentryEnvironments) {
  switch (env) {
    case SentryEnvironments.Local:
      return ' - Local Dev';
    case SentryEnvironments.Development:
      return ' - Dev';
    case SentryEnvironments.Staging:
      return ' - Staging';
    default:
      return '';
  }
}

const Header = ({ title, env }: { title: string | undefined; env: SentryEnvironments }) => {
  const envColor = env === SentryEnvironments.Production ? COLORS.iris : COLORS.slate;
  const { favicon16, favicon32, favicon96, favicon128, favicon192, favicon196, favicon270, favicon } =
    env === SentryEnvironments.Production ? favicons.production : favicons.development;
  const titlePostfix = getTitlePostfix(env);

  return (
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <title>{title || `Upbound Console${titlePostfix}`}</title>

      {/* Generics */}
      <link rel="icon" href={favicon.src} />
      <link rel="icon" type="image/png" href={favicon16.src} sizes="16x16" />
      <link rel="icon" type="image/png" href={favicon32.src} sizes="32x32" />
      <link rel="icon" type="image/png" href={favicon96.src} sizes="96x96" />
      <link rel="icon" type="image/png" href={favicon128.src} sizes="128x128" />
      <link rel="icon" type="image/png" href={favicon192.src} sizes="192x192" />
      <link rel="mask-icon" href={safariPinnedTab.src} color={envColor} />

      {/* Android */}
      <link rel="shortcut icon" type="image/png" href={favicon196.src} sizes="196x196" />

      {/* Windows 8 IE 10, Windows 8.1 + IE11 and above */}
      <meta name="app-version" content={getConfig().publicRuntimeConfig.VERSION} />
      <meta name="application-name" content={`Upbound Console${titlePostfix}`} />
      <meta name="msapplication-TileColor" content={envColor} />
      <meta name="msapplication-square70x70logo" content={favicon128.src} />
      <meta name="msapplication-square150x150logo" content={favicon270.src} />
      <meta name="msapplication-TileImage" content={favicon270.src} />
      <meta name="msapplication-config" content="none" />
    </Head>
  );
};

function redirectToLogin() {
  window.location.assign(external.accountsLogin.url(window.location.href));
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (e, q) => {
      if (!q.meta?.disableUnauthenticatedDialog && (e as any).response?.status === 401) {
        setAuthHasErrorRV(true);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: e => {
      if ((e as any).response?.status === 401) {
        setAuthHasErrorRV(true);
      }
    },
  }),
});

export default function App({ Component, pageProps }: AppPropsWithConfig) {
  const [client, setClient] = useState(false);
  const env: SentryEnvironments = getConfig().publicRuntimeConfig.SENTRY_ENVIRONMENT ?? SentryEnvironments.Local;
  const router = useRouter();
  const [authError] = useAuthHasErrorRV();
  const [authErrorHidden, setAuthErrorHidden] = useState(false);

  useEffect(() => {
    IntercomBoot();
    setClient(true);
    setModalElement();
    IntercomBoot();
  }, [setClient]);

  useEffect(() => {
    const handleRouteChange = (_url: string) => {
      IntercomUpdate();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  // Block server-side rendering
  if (!client) {
    return <Header title={Component.config?.title} env={env} />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ApolloProvider client={getUpboundGraphQLClient()}>
        <ThemeProvider theme={StyledSystemTheme}>
          <MediaContextProvider>
            <RequireLoggedIn>
              <ReRouter config={Component.config?.reRoute}>
                <SpaceGuard>
                  <AdminGuard>
                    <LegacyGuard>
                      <Global styles={globalStyle} />
                      <Template layout={Component.config?.layout} layoutProps={Component.config?.layoutProps}>
                        <Header title={Component.config?.title} env={env} />
                        <Component {...pageProps} />
                      </Template>
                      <StyledToastContainer closeButton={false} draggable={false} hideProgressBar={true} limit={1} />
                      <Modal
                        isOpen={!!authError && !authErrorHidden}
                        onClose={() => setAuthErrorHidden(true)}
                        // showCloseButton={false}
                        title="Interaction required"
                        subtitle="The console has encountered an authentication issue. We suggest attempting to sign in again."
                      >
                        <div className="flex gap-2">
                          <Button onClick={redirectToLogin}>Sign in again</Button>
                          <Button btnType="Secondary" onClick={() => setAuthErrorHidden(true)}>
                            Ignore
                          </Button>
                        </div>
                      </Modal>
                    </LegacyGuard>
                  </AdminGuard>
                </SpaceGuard>
              </ReRouter>
            </RequireLoggedIn>
          </MediaContextProvider>
        </ThemeProvider>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

// NOTE: This is required to allow us to use getConfig().publicRuntimeConfig
App.getInitialProps = () => {
  return {};
};

type TemplateProps = React.PropsWithChildren<{
  layout: React.ComponentType<React.PropsWithChildren> | boolean | undefined;
  layoutProps?: Record<string, unknown>;
}>;

const DefaultLayout = ({ children, layout }: React.PropsWithChildren<{ layout: boolean }>) =>
  layout ? <PageTemplate>{children}</PageTemplate> : <>{children}</>;

const Template: React.FC<TemplateProps> = ({ children, layout = true, layoutProps = {} }) => {
  if (typeof layout === 'boolean') {
    return (
      <DefaultLayout layout={layout} {...layoutProps}>
        {children}
      </DefaultLayout>
    );
  }

  // If a layout component is passed, use it.
  // https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#with-typescript
  const Layout = layout;

  return <Layout {...layoutProps}>{children}</Layout>;
};

const RequireLoggedIn: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { data, error } = useGetCurrentUserProfileAndAccountsQuery();
  const [currentUser, setCurrentUser] = useCurrentUserRaw();
  const [allAccounts, setAllAccounts] = useAllAccounts();

  useEffect(() => {
    if (error) {
      if (error.graphQLErrors.find(e => e.extensions?.code === 'UNAUTHENTICATED')) {
        redirectToLogin();
      } else {
        throw error;
      }
    } else if (data) {
      setCurrentUser(data.currentUser);
      setAllAccounts(data.accounts);
    }
  }, [error, data, setCurrentUser, setAllAccounts]);

  // Hide content until we have a logged in user
  if (!data || !currentUser || !allAccounts) {
    return null;
  }

  return <>{children}</>;
};

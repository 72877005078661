import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  TypePolicies,
  TypePolicy,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { CachePersistor } from 'apollo3-cache-persist';
import { GraphQLError } from 'graphql';

import { GraphQLErrorCodes } from '@/@types/models';
import { trialLimitText } from '@/constants/copy';
import * as constants from '@/constants/graphql';
import { Toast } from '@/elements/Toast';
import upboundIntrospectionResult from '@/generated/upbound-introspection-result';
import { getApiServer } from '@/utils/config';
import { log } from '@/utils/log';
import { capitalize } from '@/utils/strings';

import { typeDefs } from './clientSideSchema';
import { setAuthHasErrorRV } from './reactiveVars';

export let cachePersistor: CachePersistor<NormalizedCacheObject> | undefined;

function hasGqlErrors(gqlErrors?: Readonly<Array<GraphQLError>>): gqlErrors is Readonly<Array<GraphQLError>> {
  return !!gqlErrors;
}

function gqlErrorExtensionsCode(gqlError: Readonly<GraphQLError>): false | GraphQLErrorCodes {
  return gqlError.extensions && !!gqlError.extensions?.code && (gqlError.extensions.code as GraphQLErrorCodes);
}

function gqlErrorExtensionsResponse(gqlError: Readonly<GraphQLError>): false | Response {
  return gqlError.extensions && !!gqlError.extensions?.response && (gqlError.extensions.response as Response);
}

function hasErrorCode(gqlErrorCode: GraphQLErrorCodes, httpErrorCode?: string) {
  return function (gqlErrors: readonly GraphQLError[]) {
    return gqlErrors.some(err => {
      const gqlCode = gqlErrorExtensionsCode(err);

      if (gqlCode && gqlCode === gqlErrorCode) {
        return true;
      }

      if (httpErrorCode) {
        const gqlResponse = gqlErrorExtensionsResponse(err);

        if (gqlResponse && gqlResponse?.status.toString() === httpErrorCode) {
          return true;
        }
      }

      return false;
    });
  };
}

export function hasNotFoundError(gqlErrors: readonly GraphQLError[]) {
  return (
    hasErrorCode(GraphQLErrorCodes.NOT_FOUND_ERROR, '404')(gqlErrors) || gqlErrors.some(e => e.extensions?.code === 404)
  );
}

export function hasForbiddenError(gqlErrors: readonly GraphQLError[]) {
  return hasErrorCode(GraphQLErrorCodes.FORBIDDEN, '403')(gqlErrors);
}

export function hasLimitReachedError(gqlErrors: readonly GraphQLError[] | undefined) {
  return hasErrorCode(GraphQLErrorCodes.LIMIT_REACHED)(gqlErrors ?? []);
}

export function hasUnauthenticatedError(gqlErrors: readonly GraphQLError[]) {
  return hasErrorCode(GraphQLErrorCodes.UNAUTHENTICATED, '401')(gqlErrors);
}

export function hasInternalServerError(gqlErrors: readonly GraphQLError[]) {
  const [firstGraphQLError] = gqlErrors;

  return (
    hasErrorCode(GraphQLErrorCodes.INTERNAL_SERVER_ERROR, '500')(gqlErrors) ||
    firstGraphQLError?.message?.toLowerCase() === 'internal server error'
  );
}

export function hasRetryableError(gqlErrors: readonly GraphQLError[]) {
  return (
    hasErrorCode(GraphQLErrorCodes.RETRYABLE_ERROR, '503')(gqlErrors) ||
    gqlErrors.some(e => e.extensions?.code === 503 || e.extensions?.code === 504)
  );
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (!hasGqlErrors(graphQLErrors)) {
    return forward(operation);
  }

  if (hasUnauthenticatedError(graphQLErrors)) {
    setAuthHasErrorRV(true);
    log('Unauthenticated error from GraphQL API');
    return forward(operation);
  }

  if (hasNotFoundError(graphQLErrors)) {
    log('Not found error from GraphQL API');
    return forward(operation);
  }

  if (hasRetryableError(graphQLErrors)) {
    log('Retryable error from GraphQL API');
    return forward(operation);
  }

  if (hasInternalServerError(graphQLErrors)) {
    const bypassOperations: string[] = ['GetControlPlaneProviderConfigs'];

    log('Internal server error from GraphQL API');

    if (bypassOperations.includes(operation.operationName)) {
      return forward(operation);
    }

    return forward(operation);
  }

  if (hasLimitReachedError(graphQLErrors)) {
    log(trialLimitText);
    return forward(operation);
  }

  if (hasForbiddenError(graphQLErrors)) {
    const bypassOperations: string[] = [
      'GetControlPlane',
      'GetControlPlanePermission',
      'GetControlPlanes',
      'GetRepository',
      'DeleteControlPlaneConfiguration',
    ];

    if (bypassOperations.includes(operation.operationName)) {
      return forward(operation);
    }

    Toast({ msg: 'Permission denied.' });
    return forward(operation);
  }

  const [firstGraphQLError] = graphQLErrors;

  const { extensions } = firstGraphQLError;

  const errorBody = (extensions?.response as { body?: { error?: string; message?: string } })?.body;

  const errorMessage = errorBody?.error ?? errorBody?.message;
  if (errorMessage) {
    Toast({ msg: `${capitalize(errorMessage)}.` });
    return forward(operation);
  }

  const resourceFormOperations: string[] = ['CreateKubernetesResource', 'UpdateKubernetesResource'];

  if (resourceFormOperations.includes(operation.operationName)) {
    return forward(operation);
  }

  return forward(operation);
});

const commonUserPolicy: TypePolicy = {
  fields: {
    loginProviders: { merge: false },
    name: {
      read(_, { readField }) {
        return `${readField('firstName')} ${readField('lastName')}`;
      },
    },
  },
};

const typePolicies: TypePolicies = {
  BaseUser: commonUserPolicy,
  User: commonUserPolicy,
  CurrentUser: { ...commonUserPolicy, fields: { ...commonUserPolicy.fields, tokens: { merge: false } } },
  Crossplane: {
    fields: {
      crossplaneResourceTree: { merge: false },
      configMap: { merge: false },
    },
  },
  Mutation: { fields: { admin: { merge: true }, crossplane: { merge: true } } },
  AccountControlPlane: { keyFields: ['controlPlane', ['id']] },
  Organization: {
    fields: {
      currentUserTeams: { merge: false },
      invites: { merge: false },
      members: { merge: false },
      robots: { merge: false },
      teams: { merge: false },
    },
  },
  OrgMember: { keyFields: ['user', ['id']] },
  OrgAccount: { fields: { repositories: { merge: false } } },
  Query: { fields: { admin: { merge: true }, accounts: { merge: false }, registry: { merge: true } } },
  Robot: { fields: { teams: { merge: false }, tokens: { merge: false } } },
  ScopedTeam: { keyFields: ['id'] },
  Team: {
    fields: {
      controlPlanes: { merge: false },
      members: { merge: false },
      repositories: { merge: false },
      robots: { merge: false },
    },
  },
  TeamMember: { keyFields: ['user', ['id']] },
  TeamControlPlane: { keyFields: ['controlPlane', ['id']] },
  TeamRepository: { keyFields: ['repository', ['id']] },
  Namespace: { fields: { xrds: { merge: false } } },
  CompositeResourceClaim: { fields: { metadata: { merge: true } } },
  CompositeResourceDefinition: { fields: { spec: { merge: true }, metadata: { merge: true } } },
  CustomResourceDefinition: { fields: { spec: { merge: true }, metadata: { merge: true } } },
  GenericResource: {
    fields: {
      __typename: {
        read: (_, { readField }) => {
          const kind = readField('kind');
          if (kind === 'Function') {
            return 'XgqlFunction';
          }
          if (kind === 'CompositionRevision') {
            return 'CompositionRevision';
          }
          if (kind === 'FunctionRevision') {
            return 'FunctionRevision';
          }
          return 'GenericResource';
        },
      },
    },
  },
};

export const getUpboundApolloClientOptions = (link?: ApolloLink): ApolloClientOptions<NormalizedCacheObject> => {
  const cache = new InMemoryCache({ possibleTypes: upboundIntrospectionResult.possibleTypes, typePolicies }) as any;

  const opts: ApolloClientOptions<NormalizedCacheObject> = { cache, resolvers: {}, typeDefs };

  if (link) {
    return { ...opts, link };
  }

  cachePersistor = new CachePersistor({
    cache,
    key: constants.CACHE_PERSIST_KEY,
    maxSize: 2097152, // 2MB
    storage: window.localStorage,
  });

  const httpLink = new HttpLink({
    uri: `${getApiServer()}/graphql`,
    credentials: 'include',
    headers: { 'Upbound-API-Version': 'old' },
  });

  const composedLink = ApolloLink.from([errorLink, httpLink]);

  return { ...opts, defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } }, link: composedLink };
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

export function getUpboundGraphQLClient(): ApolloClient<NormalizedCacheObject> {
  if (!apolloClient) {
    apolloClient = new ApolloClient(getUpboundApolloClientOptions());
  }
  return apolloClient;
}

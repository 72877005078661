import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { DependencyList, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { TIMING } from '@/constants/timing';

import { isClient } from './checks';
import { log } from './log';

export const useIsActive = (href?: Pick<LinkProps, 'href'>['href'], exact = false) => {
  const router = useRouter();

  if (href?.toString().startsWith('http') || !href?.toString().startsWith('/')) {
    return false;
  }

  try {
    const url = new URL((href || '') as string, window.location.origin);

    if (exact) {
      return router.asPath === url.pathname;
    }
    return router.asPath.startsWith(url.pathname);
  } catch (e) {
    return false;
  }
};

export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useExpandable() {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (!isExpanded) {
      if (contentHeight !== 0) {
        setContentHeight(0);
        return;
      }

      return;
    }

    if (!contentRef || !contentRef.current || !contentRef.current.scrollHeight) {
      return;
    }

    setContentHeight(contentRef.current.scrollHeight);
  }, [isExpanded, contentRef]);

  return { isExpanded, setExpanded, contentRef, contentHeight };
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

function getScrollPosition() {
  if (!isClient) {
    return { x: 0, y: 0 };
  }

  const position = document.body.getBoundingClientRect();

  return { x: position.left, y: position.top };
}

interface Postion {
  x: number;
  y: number;
}

interface EffectCallbackArgs {
  prevPos: Postion;
  currPos: Postion;
}

type EffectCallback = (arg: EffectCallbackArgs) => void | ((arg: EffectCallbackArgs) => void | undefined);

/**
 * This hook can be used to "listen" to the scroll position and do something as a result
 * @param effect Callback to run when hook is triggered
 * @param deps Optional list of dependencies that cause hook to fire
 *
 * Usage example:
 * ```
 * const [isSlimNav, toggleIsSlimNav] = useState(false);
 * useScrollPosition(({ currPos }) => toggleIsSlimNav(currPos.y !== 0), [isSlimNav]);
 * ```
 */
export function useScrollPosition(effect: EffectCallback, deps?: DependencyList | undefined) {
  const position = useRef(getScrollPosition());

  let throttleTimeout: ReturnType<typeof setTimeout> | null = null;

  const callBack = () => {
    const currPos = getScrollPosition();
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (throttleTimeout === null) {
        throttleTimeout = setTimeout(callBack, TIMING.fast('number', 'ms'));
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}

export function useRedirect(replace = false) {
  const router = useRouter();
  return (url: string, query?: string | null | ParsedUrlQueryInput | undefined) => {
    const redirectObject = { pathname: url, query };
    log('Redirecting', redirectObject);

    if (replace) {
      router.replace(redirectObject);
      return;
    }

    router.push(redirectObject);
  };
}

export function usePortal(id: string) {
  const rootElementRef = useRef<HTMLDivElement>();

  // Create the portal div
  useEffect(() => {
    const rootElem = document.createElement('div');

    rootElementRef.current = rootElem;

    return () => {
      rootElementRef.current = undefined;
      rootElem.remove();
    };
  }, []);

  // Attach the portal div to the DOM
  useEffect(() => {
    if (!rootElementRef.current) {
      return;
    }

    if (!id) {
      document.body.appendChild(rootElementRef.current);
      return;
    }

    const parentElement = document.querySelector(`#${id}`);

    if (!parentElement) {
      document.body.appendChild(rootElementRef.current);
      return;
    }

    parentElement.appendChild(rootElementRef.current);
  }, [id, rootElementRef]);

  return rootElementRef.current;
}

/** useHash is a getter function for the hash from the url.
 * The initialValue arg is an optional argument
 * that if present will be used to set the hash to on initial render
 * */
export const useHash = (initialValue?: string) => {
  const router = useRouter();
  const [hash, setHash] = useState(window.location.hash.slice(1));

  useEffect(() => {
    const onNextJSHashChange = (url: string) => setHash(url.split('#')[1]);
    const onWindowHashChange = (event: HashChangeEvent) => {
      setHash(event.newURL.split('#')[1]);
    };
    router.events.on('hashChangeStart', onNextJSHashChange);
    window.addEventListener('hashchange', onWindowHashChange);
    return () => {
      router.events.off('hashChangeStart', onNextJSHashChange);
      window.removeEventListener('hashchange', onWindowHashChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (initialValue && !window.location.hash) {
      router.push({ ...router, hash: initialValue }, undefined, { shallow: true });
    }
  }, []);

  return hash;
};

interface RouterParamsParamConfig<T extends string = string> {
  name: string;
  test?: (value: string) => value is T;
  disableThrowOnMissing?: boolean;
  defaultValue?: T;
}

type RouterParamsParamNameOrConfig = string | RouterParamsParamConfig;

type RouterParamsExtractString<T> = T extends string ? T : T extends { name: infer N } ? N : never;
type RouterParamsExtractType<T> = T extends string
  ? string
  : T extends { test: (value: any) => value is infer U }
    ? U
    : string;

type RouterParamsExtractParam<T, Name> = T extends (infer U)[]
  ? U extends { name: infer N }
    ? N extends Name
      ? U
      : never
    : U extends string
      ? U extends Name
        ? U
        : never
      : never
  : never;

type RouterParamsReturnType<T extends RouterParamsParamNameOrConfig[]> = {
  [P in RouterParamsExtractString<T[number]>]: RouterParamsExtractType<RouterParamsExtractParam<T, P>>;
};

/**
 * @description
 * When using the typed test functions, make sure to use the `as const` assertion.
 * If you provide an object, you can also provide a test function that will be used to validate the value.
 * If the value is invalid, an error will be thrown.
 * The returned type should be a string (T extends string)
 *
 * @example
 * ```ts
 * const { orgName, spaceType } = useRouterParams([
 *  'orgName',
 * {
 *  name: 'spaceType',
 *  test: (value: string): value is SpaceType => ['connected', 'managed'].includes(value),
 * }] as const)
 * ```
 */
export function useRouterParams<T extends string | RouterParamsParamConfig>(
  params: readonly T[],
): RouterParamsReturnType<T[]> {
  const router = useRouter();

  return params.reduce<RouterParamsReturnType<T[]>>(
    (result, param) => {
      const name = typeof param === 'string' ? param : param.name;

      if (!(name in router.query)) {
        if (typeof param !== 'string' && param.disableThrowOnMissing) {
          if (param.defaultValue) {
            return {
              ...result,
              [name]: param.defaultValue,
            };
          }
          return result;
        }
        throw new Error(`Missing required param: ${name}`);
      }

      const queryValue: string | string[] = router.query[name] ?? '';
      const value: string = decodeURIComponent(Array.isArray(queryValue) ? queryValue.join(', ') : queryValue);

      if (typeof param !== 'string' && param.test && !param.test(value)) {
        throw new Error(`Invalid value for param ${name}`);
      }

      return {
        ...result,
        [name]: value,
      };
    },
    {} as RouterParamsReturnType<T[]>,
  );
}

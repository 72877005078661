import React, { ComponentType, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { MainNav } from './MainNav';
import { TrialCountdownTimer } from './TrialCountdownTimer';

export const PageTemplate: React.FC<
  React.PropsWithChildren<{
    className?: string;
    isNavVisible?: boolean;
    hideRoutes?: boolean;
    navComponent?: ComponentType<PropsWithChildren> | false;
    belowNavComponent?: ComponentType<PropsWithChildren> | false;
  }>
> = ({
  children,
  className,
  isNavVisible,
  navComponent: NavComponent = MainNav,
  hideRoutes = false,
  belowNavComponent: BelowNavComponent = false,
}) => {
  // page-container is in apps/console/src/index.css and is needed because it adds a complex child selector
  const baseClass = 'bg-neutral-shades-50 h-full w-full flex grow flex-wrap justify-center page-container';

  return (
    <div className="flex flex-col w-full min-h-full bg-neutral-shades-25">
      <TrialCountdownTimer />
      {!!NavComponent && (
        <nav className="flex h-[60px] w-full bg-neutral-1000 overflow-x-hidden sticky z-50 top-0 left-0 py-[5px] px-[30px]">
          <NavComponent hideRoutes={hideRoutes} />
        </nav>
      )}
      {!!BelowNavComponent && <BelowNavComponent />}

      <main className={twMerge(baseClass, isNavVisible ? 'p-[30px]' : 'p-0', className)}>{children}</main>
    </div>
  );
};
